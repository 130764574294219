<template>
    <div class="files">
        <h1 >{{ $t('titles.files_overview') }}</h1>
        <FileTable :picker="false" />
    </div>
</template>

<script>
import FileTable from "@admin/components/cms/file/FileTable";

export default {
    name: "Files",
    components: {
        FileTable
    }
    
}
</script>

<style scoped>
.files{
    display: flex;
    flex-direction: column;
    height: 100%;
}
</style>